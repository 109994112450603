import React from "react"
import {Link} from "gatsby";
import {MdOutlineArrowBack} from "react-icons/md";
import icon from "../images/icon.png";
import * as styles from "../sass/components/backtotop.module.scss";

const BackToTop = () => {
  return (
    <div className={styles.section}>
      <Link to="/">
        <MdOutlineArrowBack />
        <img src={icon} alt="まもる" />
        <span>ホームへ</span>
      </Link>
    </div>
  );
}

export default BackToTop