import React from "react"
import {Link} from "gatsby";
import icon from "../images/icon.png";
import * as styles from "../sass/components/page-header.module.scss";

const PageHeader = ({title}) => {
  return (
    <div className={styles.pageHeader}>
      <Link to="/"><img src={icon} alt="まもるトップページへ" /></Link>
      <h1>{title}</h1>
    </div>
  );
};

export default PageHeader;