import React from 'react';
import { AiOutlineFilePdf, AiOutlineFileWord } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";

import Layout from "../containers/Layout";
import Meta from "../containers/Meta";
import PageHeader from "../components/PageHeader";
import BackToTop from "../components/BackToTop";

import * as styles from "../sass/documents.module.scss";
import adminPdf from "../images/administrator-guide.pdf";
import driverPdf from "../images/driver-guide.pdf";
import thumb1 from "../images/thumb1.jpg";
import thumb2 from "../images/thumb2.jpg";
import thumb3 from "../images/thumb3.jpg";

const DocumentsPage = () => {
  return (
    <Layout>
      <Meta title={"資料ダウンロード"} />

      <PageHeader title={"資料ダウンロード"} />

      <div className={styles.documents}>
        <a href={adminPdf} className={`${styles.btn} ${styles.btnRed}`} download={"管理者向けマニュアル.pdf"}>
          <span className={styles.thumbnail}>
            <img src={thumb1} alt="管理者向けマニュアル" />
          </span>
          <span className={styles.body}>
            <strong>管理者向けマニュアル</strong>
            <span>
              <AiOutlineFilePdf /><span>PDF</span>・2022/09/23 更新
            </span>
          </span>
          <span className={styles.icon}>
            <FiDownload />
          </span>
        </a>

        <a href={driverPdf} className={`${styles.btn} ${styles.btnGreen}`} download={"ドライバー向けマニュアル.pdf"}>
          <span className={styles.thumbnail}>
            <img src={thumb2} alt="ドライバー向けマニュアル" />
          </span>
          <span className={styles.body}>
            <strong>ドライバー向けマニュアル</strong>
            <span>
              <AiOutlineFilePdf /><span>PDF</span>・2022/09/23 更新
            </span>
          </span>
          <span className={styles.icon}>
            <FiDownload />
          </span>
        </a>

        <a href={"/files/message.docx"} className={styles.btn} download={"保護者向け資料.docx"}>
          <span className={styles.thumbnail}>
            <img src={thumb3} alt="保護者向け資料" />
          </span>
          <span className={styles.body}>
            <strong>保護者向けメッセージ</strong>
            <span>
              <AiOutlineFileWord /><span>WORD</span>・2022/09/23 更新
            </span>
          </span>
          <span className={styles.icon}>
            <FiDownload />
          </span>
        </a>
      </div>

      <BackToTop />
    </Layout>
  );
}

export default DocumentsPage;